const Linkedin = ({ iconclass }) => {
    return (
        <svg className={`icon ${iconclass}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <g id="Outline">
                <g id="LinkedIn">
                <path class="icon-border" d="M9.04,5.92L0,42.08h38.96L48,5.92H9.04ZM37.46,40.27H2.4L10.54,7.73h35.06l-8.14,32.54Z"/>
                <path class="icon-stroke" d="M13.14,38.46H4.8l4.73-18.9v15.28c0,1.99,1.62,3.62,3.62,3.62Z"/>
                <path class="icon-stroke" d="M18.18,33.8c0,.26-.06.37-.35.37-1.21-.02-2.43-.01-3.64,0-.27,0-.34-.07-.34-.34,0-2.18,0-4.37,0-6.56s0-4.33,0-6.5c0-.31.09-.39.39-.38,1.19.02,2.39.01,3.59,0,.28,0,.36.08.36.36,0,4.35,0,8.7,0,13.05Z"/>
                <path class="icon-stroke" d="M18.54,16.08c0,1.39-1.13,2.53-2.52,2.54-1.37,0-2.53-1.16-2.52-2.54,0-1.38,1.14-2.49,2.52-2.49,1.39,0,2.51,1.11,2.52,2.49Z"/>
                <path class="icon-stroke" d="M34.19,33.75c0,.41,0,.41-.42.41-1.2,0-2.39,0-3.59,0-.3,0-.37-.1-.37-.38,0-2.25.01-4.51,0-6.77,0-.46-.04-.92-.12-1.37-.22-1.29-1.11-1.93-2.42-1.76-1.2.16-1.86.8-2.05,2.05-.07.47-.12.96-.12,1.43-.01,2.14-.01,4.27,0,6.41,0,.3-.08.39-.39.39-1.2-.02-2.41-.01-3.62,0-.24,0-.33-.06-.33-.32,0-4.37,0-8.74,0-13.11,0-.27.09-.34.35-.34,1.15.01,2.31.01,3.46,0,.26,0,.36.07.35.34-.02.48,0,.96,0,1.44.6-.85,1.35-1.47,2.32-1.81,1.5-.51,3-.42,4.43.23,1.28.58,1.9,1.7,2.21,3.02.24,1.03.29,2.08.29,3.13,0,2.34,0,4.67,0,7.01Z"/>
                <path class="icon-stroke" d="M38.47,28.44v-15.28c0-1.99-1.62-3.62-3.62-3.62h8.34l-4.73,18.9Z"/>
                </g>
            </g>
        </svg>
    )
}

export default Linkedin;
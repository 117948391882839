const WordmarkSN = () => {
    return (
    <svg className="wordmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.3 48">
        <g id="Logo">
            <path class="icon-border" d="M141.21,42.08l9.04-36.16H9.09L.05,42.08h141.16ZM139.57,39.98H16.73s-2.18-2.18-2.18-2.18l-.54,2.18H2.74l7.99-31.96h20.24l2.18,2.18.54-2.18h113.87l-7.99,31.96Z"/>
            <g>
            <polygon class="icon-stroke" points="30.41 21.23 28.84 27.51 17.74 16.4 12.37 37.88 5.43 37.88 12.37 10.12 19.31 10.12 30.41 21.23"/>
            <polygon class="icon-stroke" points="42.28 10.12 35.34 37.88 28.4 37.88 17.29 26.77 18.86 20.49 29.97 31.6 35.34 10.12 42.28 10.12"/>
            <polygon class="sn-red" points="17.6 37.88 15.13 35.41 16.7 29.13 25.45 37.88 17.6 37.88"/>
            <polygon class="sn-red" points="30.1 10.12 32.57 12.59 31 18.87 22.25 10.12 30.1 10.12"/>
            </g>
            <g>
            <polygon class="icon-stroke" points="141.86 22.18 137.93 37.87 130.88 37.87 132.2 32.61 126.55 26.96 123.82 37.88 116.77 37.88 120.69 22.18 127.75 22.18 133.39 27.83 134.8 22.18 141.86 22.18"/>
            <path class="icon-stroke" d="M97.32,22.18l-3.92,15.69h21.21l3.92-15.69h-21.21ZM108.41,34.42h-7.1l2.19-8.77h7.1l-2.19,8.77Z"/>
            <polygon class="icon-stroke" points="84.17 37.87 91.23 37.87 95.15 22.18 88.1 22.18 84.17 37.87"/>
            <polygon class="icon-stroke" points="85.93 22.18 85.06 25.64 78.01 25.64 75.84 34.31 75.84 34.31 74.94 37.88 67.89 37.88 70.95 25.64 63.9 25.64 64.76 22.18 85.93 22.18"/>
            <path class="icon-stroke" d="M41.43,22.18l-3.03,12.13h0l-.89,3.57h7.05l1.54-6.17h7.05l-1.54,6.17h7.05l.89-3.57h0l3.03-12.13h-21.16ZM54.03,28.24h-7.05l.65-2.6h7.05l-.65,2.6Z"/>
            </g>
            <g>
            <polygon class="sn-red" points="48.74 12.53 48.35 14.09 58.15 14.09 57.65 16.08 57.65 16.09 57.16 18.06 56.56 20.47 41.86 20.47 42.46 18.06 52.26 18.06 52.65 16.5 42.85 16.5 43.45 14.09 43.84 12.53 44.44 10.12 59.14 10.12 58.54 12.53 48.74 12.53"/>
            <polygon class="sn-red" points="75.07 10.12 74.47 12.53 69.57 12.53 67.58 20.47 62.68 20.47 64.67 12.53 59.77 12.53 60.37 10.12 75.07 10.12"/>
            <path class="sn-red" d="M76.29,10.12h0s0,0,0,0l-2.59,10.35h4.9l.99-3.97.05-.2.18.2,3.67,3.97h5.66l-3.64-3.97h3.87s.01,0,.01,0l.99-3.97.6-2.41h-14.7ZM85.1,14.09h-4.9l.39-1.56h4.9l-.39,1.56Z"/>
            <path class="sn-red" d="M94.53,20.47h-4.9l2.59-10.35h4.9l-2.59,10.35Z"/>
            <path class="sn-red" d="M98.33,10.12h0s0,0,0,0l-.99,3.97h0l-.04.17-1.55,6.21h4.9l.99-3.97h9.8s0,0,0,0l.99-3.97.6-2.41h-14.7ZM102.63,12.53h4.9l-.39,1.56h-4.9l.39-1.56Z"/>
            <polygon class="sn-red" points="118.55 12.53 118.16 14.09 127.97 14.09 127.37 16.5 117.56 16.5 117.17 18.06 126.97 18.06 126.37 20.47 111.67 20.47 112.27 18.06 114.26 10.12 128.96 10.12 128.36 12.53 118.55 12.53"/>
            <polygon class="sn-red" points="134.47 12.53 134.08 14.09 143.88 14.09 143.38 16.08 143.38 16.09 142.89 18.06 142.29 20.47 127.59 20.47 128.19 18.06 137.99 18.06 138.38 16.5 128.58 16.5 129.18 14.09 129.57 12.53 130.17 10.12 144.87 10.12 144.27 12.53 134.47 12.53"/>
            </g>
        </g>
    </svg>
    )
}

export default WordmarkSN;
const Instagram = ({ iconclass }) => {
    return (
        <svg className={`icon ${iconclass}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <g id="Outline">
                <g id="Instagram">
                <path class="icon-border" d="M9.04,5.92L0,42.08h38.96L48,5.92H9.04ZM37.46,40.27H2.4L10.54,7.73h35.06l-8.14,32.54Z"/>
                <path class="icon-stroke" d="M13.14,38.46H4.8l4.73-18.9v15.28c0,1.99,1.62,3.62,3.62,3.62Z"/>
                <path class="icon-stroke" d="M24,18.28c-3.15,0-5.71,2.55-5.71,5.71s2.55,5.71,5.71,5.71,5.71-2.55,5.71-5.71-2.55-5.71-5.71-5.71ZM26.62,26.61c-.7.7-1.64,1.08-2.62,1.08s-1.93-.39-2.62-1.08c-.7-.7-1.08-1.64-1.08-2.62s.39-1.93,1.08-2.62c.7-.7,1.64-1.08,2.62-1.08s1.93.39,2.62,1.08c.7.7,1.08,1.64,1.08,2.62s-.39,1.93-1.08,2.62Z"/>
                <path class="icon-stroke" d="M30.88,17.12c.25.25.39.59.39.94s-.14.69-.39.94c-.25.25-.59.39-.94.39s-.7-.14-.94-.39c-.25-.25-.39-.59-.39-.94s.14-.69.39-.94c.25-.25.59-.39.94-.39s.69.14.94.39Z"/>
                <path class="icon-stroke" d="M35.05,19.42c-.05-1.18-.24-1.99-.51-2.7-.29-.73-.66-1.35-1.28-1.97-.62-.62-1.24-1-1.97-1.28-.71-.28-1.52-.46-2.7-.51-1.19-.05-1.56-.07-4.58-.07s-3.4,0-4.58.07c-1.18.05-1.99.24-2.7.51-.73.29-1.35.66-1.97,1.28-.62.62-1,1.24-1.28,1.97-.28.71-.46,1.52-.51,2.7-.05,1.19-.07,1.56-.07,4.58s0,3.4.07,4.58c.05,1.18.24,1.99.51,2.7.29.73.66,1.35,1.28,1.97s1.24,1,1.97,1.28c.71.27,1.52.46,2.7.51,1.19.05,1.56.07,4.58.07s3.4,0,4.58-.07c1.18-.05,1.99-.24,2.7-.51.73-.29,1.35-.66,1.97-1.28s1-1.24,1.28-1.97c.27-.71.46-1.52.51-2.7.05-1.19.07-1.56.07-4.58s0-3.4-.07-4.58ZM33.05,28.48c-.05,1.08-.23,1.67-.38,2.07-.2.51-.44.89-.83,1.28-.39.39-.76.63-1.28.83-.39.16-.98.33-2.07.38-1.17.05-1.52.06-4.49.06s-3.32,0-4.49-.06c-1.08-.05-1.67-.23-2.07-.38-.51-.2-.89-.44-1.28-.83-.39-.39-.63-.76-.83-1.28-.16-.39-.33-.98-.38-2.07-.05-1.17-.06-1.52-.06-4.49s0-3.32.06-4.49c.05-1.08.23-1.67.38-2.06.2-.52.44-.89.83-1.28.39-.39.76-.63,1.28-.83.39-.16.98-.33,2.07-.38,1.17-.05,1.52-.06,4.49-.06s3.32,0,4.49.06c1.08.05,1.67.23,2.07.38.51.2.89.44,1.28.83.39.39.63.76.83,1.28.16.39.33.98.38,2.06.05,1.17.06,1.53.06,4.49s0,3.32-.06,4.49Z"/>
                <path class="icon-stroke" d="M38.47,28.44v-15.28c0-1.99-1.62-3.62-3.62-3.62h8.34l-4.73,18.9Z"/>
                </g>
            </g>
        </svg>
    )
}

export default Instagram;